import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import CopyListTableItem from "@/entity/flowable/CopyListTableItem";
import ProcessWorkspace from "@/modules/flowable/workspace/process-workspace/process-workspace.vue";
import API from "@/api/flowable/workspace/copy";

@Options({
    name: "app-flowable-workspace-copy",
    components: {
        ProcessWorkspace,
    },
})
export default class Copy extends BaseTableMixins<CopyListTableItem> implements Partial<BaseTableInterface<CopyListTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new CopyListTableItem();
        this.editModalItem = new CopyListTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //处理流程
    public flowProcessing(item: any) {
        item.isProcess = false;
        (this.$refs.processView as any).show(item);
    }

    //流程提交
    public onFlowSubmit(e: any) {
        if ("RefreshTable" === e) this.getList(0);
    }
}
